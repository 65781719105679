import React, { useState } from 'react';
import { Layout } from '@vs/vsf-kit';
import { renderRoutes } from '@vs/vsf-boot';

import './index.less';
import logo from '@/pages/index/img/guda_logo.png';
import img2 from '@/pages/index/img/img.png';
import map from '@/pages/index/img/map.png';
import mapLine from '@/pages/index/img/mapLine.png';
import router from '@/pages/index/img/rputer.png';

const { Sider } = Layout as any;
const Alist = [
  {
    address: '#index',
    name: '首页',
    path:'/'
  },
  {
    address: '#introduce',
    name: '功能介绍',
    path:'/'
  },
  {
    address: '#app',
    name: '获取app',
    path:'/'
  },
  {
    address: '/recharge',
    name: '充值',
    path:'/recharge'
  }
];
const Container: any = props => {
  const { route } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [type ,setType] = useState('#index')

  return (
    <div className="vsf-layout">
      <div className="vsf-layout-header">
        {/* <div className="vsf-layout-header_span">
          <img className="vsf-layout-header_span_img" src={logo}></img>
          <div className="vsf-layout-header_text">咕哒自驾</div>
        </div>

        <div className="vsf-layout-header_img_content">
          <div className="vsf-layout-header_img"></div>
          <span className="vsf-layout-header_img_content_text">
            扫一扫，立刻下载
          </span>
        </div> */}
        {/* <div>咕哒自驾</div>
        <div>咕哒自驾</div> */}
        <div className="vsf-layout-header_content">
          <div className="vsf-layout-header_content_name">咕哒自驾</div>
          <div className="vsf-layout-header_content_title">
            {Alist.map((v, i) => {
              // if(!v.path){
                return (
                  <a
                    className="vsf-layout-header_content_title_a"
                    key={i}
                    href={`${v.address}`}
                    onClick={()=>{
                      props.history.push(v?.path)
                    }}
                  >
                    {v.name}
                  </a>
                );
              // }else {
              //   return (
              //     <span onClick={()=>{
              //       // console.log(v?.path)
              //       // console.log(props,'props')
              //       props.history.push(v?.path)
              //       console.log('哈哈哈')
              //     }}>{v.name}</span>
              //   )
              // }
            })}
          </div>
        </div>
      </div>
      {/* <div className="vsf-layout-header2"></div>
      <div className="vsf-layout-content">
        <div className="vsf-layout-content_img" id="index">
          <div className="vsf-layout-content_img_logo">
            <img src={logo} className="vsf-layout-content_img_logo_img"></img>
            <p className="vsf-layout-content_img_logo_p1">咕哒自驾</p>
            <p className="vsf-layout-content_img_logo_p">
              专业定制走西域，享受才是旅行真谛
            </p>
            <a className="vsf-layout-content_img_logo_a">点击获取</a>
          </div>
          <img src={img2} className="vsf-layout-content_img_img1"></img>
        </div>
        <div
          className="vsf-layout-content_img"
          id="introduce"
          style={{ background: '#EDF6FF', marginTop: '10px' }}
        >
          <div className="vsf-layout-content_img_logo">
            <p className="vsf-layout-content_img_logo_p">
              咕哒自驾是一款提供基于地理位置的自驾线路规划及导航的智能导游APP
            </p>
          </div>
          <img src={map} className="vsf-layout-content_img_img1"></img>
        </div>
        <div className="vsf-layout-content_img" style={{ marginTop: '10px' }}>
          <div className="vsf-layout-content_img_logo">
            <p className="vsf-layout-content_img_logo_p">
              【自驾线路】涵盖川藏游热门自驾线路，专业自驾线路规划
            </p>
          </div>
          <img src={router} className="vsf-layout-content_img_img1"></img>
        </div>
        <div
          className="vsf-layout-content_img"
          style={{ background: '#EDF6FF', marginTop: '10px' }}
        >
          <div className="vsf-layout-content_img_logo">
            <p className="vsf-layout-content_img_logo_p">
              {' '}
              【线路规划及导航】提供基于地理位置的线路规划及导航
            </p>
          </div>
          <img src={mapLine} className="vsf-layout-content_img_img1"></img>
        </div>
        <div
          className="vsf-layout-content_img1"
          id="app"
          style={{ marginTop: '10px', width: '100% ', margin: '0 auto' }}
        >
          <div
            className="vsf-layout-content_img_logo"
            style={{ width: '100%' }}
          >
            <p className="vsf-layout-content_img_logo_p1">立即下载咕哒自驾</p>
            <p className="vsf-layout-content_img_logo_p">
              {' '}
              咕哒自驾，为喜爱高原自驾游的达人们准备的出行神器
            </p>
            <div className="vsf-layout-content_img_logo_btn">
              <button
                onClick={() => {
                  alert('尽情期待');
                }}
                className="vsf-layout-content_img_logo_btn_item"
              >
                安卓下载
              </button>
              <button
                onClick={() => {
                  alert('尽情期待');
                }}
                className="vsf-layout-content_img_logo_btn_item"
              >
                ios下载
              </button>
            </div>
          </div>
        </div>
        <div className="vsf-layout-main">{renderRoutes(route.routes)}</div>
      </div>
      <div className="vsf-layout-foot">
        <p className="vsf-layout-foot_p">
          Copyright 2022.All Rights Reserved Theme by 杭州零维软件有限公司.
          浙ICP备2021009910号
        </p>
      </div> */}
      <div className="vsf-layout-main">{renderRoutes(route.routes)}</div>
    </div>
  );
};

export default Container;
