import React from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { Layout, Page, Section } from '@vs/vsf-kit';
import './index.less';
// import img1 from './img/img1.webp'
// import img2 from './img/img2.png'

import logo from '@/pages/index/img/guda_logo.png';
import img2 from '@/pages/index/img/img.png';
import map from '@/pages/index/img/map.png';
import mapLine from '@/pages/index/img/mapLine.png';
import router from '@/pages/index/img/rputer.png';

const Alist = [
  {
    address: '',
    name: '用户协议',
  },
  {
    address: '',
    name: '隐私协议',
  },
  {
    address: '',
    name: '公安部网络犯罪举报网站',
  },
  {
    address: '',
    name: '网上有害信息举报专区',
  },
  {
    address: '',
    name: '12318全国全国市场文化举报网站',
  },
];
const Index = props => {
  return (
    <div className="index_content">
      <div className="vsf-layout-header2"></div>
      <div className="vsf-layout-content">
        <div className="vsf-layout-content_img" id="index">
          <div className="vsf-layout-content_img_logo">
            <img src={logo} className="vsf-layout-content_img_logo_img"></img>
            <p className="vsf-layout-content_img_logo_p1">咕哒自驾</p>
            <p className="vsf-layout-content_img_logo_p">
              专业定制走西域，享受才是旅行真谛
            </p>
            <a className="vsf-layout-content_img_logo_a">点击获取</a>
          </div>
          <img src={img2} className="vsf-layout-content_img_img1"></img>
        </div>
        <div
          className="vsf-layout-content_img"
          id="introduce"
          style={{ background: '#EDF6FF', marginTop: '10px' }}
        >
          <div className="vsf-layout-content_img_logo">
            <p className="vsf-layout-content_img_logo_p">
              咕哒自驾是一款提供基于地理位置的自驾线路规划及导航的智能导游APP
            </p>
          </div>
          <img src={map} className="vsf-layout-content_img_img1"></img>
        </div>
        <div className="vsf-layout-content_img" style={{ marginTop: '10px' }}>
          <div className="vsf-layout-content_img_logo">
            <p className="vsf-layout-content_img_logo_p">
              【自驾线路】涵盖川藏游热门自驾线路，专业自驾线路规划
            </p>
          </div>
          <img src={router} className="vsf-layout-content_img_img1"></img>
        </div>
        <div
          className="vsf-layout-content_img"
          style={{ background: '#EDF6FF', marginTop: '10px' }}
        >
          <div className="vsf-layout-content_img_logo">
            <p className="vsf-layout-content_img_logo_p">
              {' '}
              【线路规划及导航】提供基于地理位置的线路规划及导航
            </p>
          </div>
          <img src={mapLine} className="vsf-layout-content_img_img1"></img>
        </div>
        <div
          className="vsf-layout-content_img1"
          id="app"
          style={{ marginTop: '10px', width: '100% ', margin: '0 auto' }}
        >
          <div
            className="vsf-layout-content_img_logo"
            style={{ width: '100%' }}
          >
            <p className="vsf-layout-content_img_logo_p1">立即下载咕哒自驾</p>
            <p className="vsf-layout-content_img_logo_p">
              {' '}
              咕哒自驾，为喜爱高原自驾游的达人们准备的出行神器
            </p>
            <div className="vsf-layout-content_img_logo_btn">
              <button
                onClick={() => {
                  alert('尽情期待');
                }}
                className="vsf-layout-content_img_logo_btn_item"
              >
                安卓下载
              </button>
              <button
                onClick={() => {
                  alert('尽情期待');
                }}
                className="vsf-layout-content_img_logo_btn_item"
              >
                ios下载
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="index_content_foot">
        <p>
          {Alist.map((v, i) => {
            return (
              <a
                key={i}
                className="index_content_foot_a"
                style={{ border: i < Alist.length - 1 ? '' : 0 }}
              >
                {v.name}
              </a>
            );
          })}
        </p>
        <p>
          <div style={{ marginRight: '10px' }}>
            {' '}
            Copyright 2023.All Rights Reserved Theme by
            成都咕哒数智科技有限公司.{' '}
            <a href="https://beian.miit.gov.cn" target="_blank">
              蜀ICP备2023005360号-1
            </a>
          </div>
        </p>
        <p>
          <div>客服邮箱：2498040173@qq.com</div>
        </p>
      </div>
      <div className="vsf-layout-foot">
        <p className="vsf-layout-foot_p">
          Copyright 2023.All Rights Reserved Theme by 成都咕哒数智科技有限公司.{' '}
          <a href="https://beian.miit.gov.cn" target="_blank">
            蜀ICP备2023005360号-1
          </a>
          <div style={{ textAlign: 'center' }}>
            经营许可证编号：川B2-20230576;川公网安备51019002006029号
          </div>
        </p>
      </div>
    </div>
  );
};

export default Index;
