import { Button, Input } from 'antd';
import React, { useState } from 'react';
import './index.less';
const Index = props => {
  const arr = ['6', '60', '120', '200', '500', '1000', 'other'];
  const [money, setMoney] = useState('6');
  const [active, setActive] = useState('6');
  return (
    <div className="recharge">
      <div className="recharge__header"></div>
      <div className="recharge__content">
        <div className="recharge__content__header">
          <img
            className="recharge__content__header__img"
            src="https://p2.music.126.net/SUeqMM8HOIpHv9Nhl9qt9w==/109951165647004069.jpg?imageView=1&type=webp&thumbnail=160y160"
          ></img>
          <div className="recharge__content__header__name">
            <span>米醋催</span>
            <span>我的余额: 20元</span>
          </div>
        </div>
        <div style={{ fontWeight: 'bold' }}>选择支付方式</div>
        <div
          style={{
            fontSize: '14px',
            color: 'rgba(0,0,0,.4)',
            marginBottom: '10px',
          }}
        >
          禁止未成年人充值消费
        </div>
        <Button
          style={{
            background: 'red',
            width: '100px',
            color: '#fff',
            marginBottom: '30px',
          }}
        >
          银行卡充值
        </Button>
        <div style={{ fontWeight: 'bold' }}>选择充值金额</div>

        <div className="recharge__content__money">
          {arr.map(v => {
            if (v === 'other') {
              return (
                <div
                  className="recharge__content__money__item"
                  onClick={() => {
                    setActive(v);
                  }}
                  style={{ border: active === v ? '1px solid red' : '' }}
                >
                  <Input
                    style={{ border: '0', color: '#000' }}
                    placeholder="请输入"
                    onChange={e => {
                      setMoney(e.target.value);
                    }}
                  ></Input>
                </div>
              );
            }
            return (
              <div
                className="recharge__content__money__item"
                onClick={(e: any) => {
                  setMoney(e?.target?.innerText?.split('元')[0]);
                  setActive(v);
                }}
                style={{ border: active === v ? '1px solid red' : '' }}
              >
                {v}元
              </div>
            );
          })}
        </div>
        <div style={{ marginTop: '30px', marginBottom: '30px' }}>
          支付金额<span style={{ color: 'red' }}>{money}</span>元
        </div>
        <Button
          style={{
            background: 'red',
            width: '100px',
            color: '#fff',
            border: '1px solid red',
          }}
          shape="round"
          onClick={() => {
            alert('充值中，请稍等');
          }}
        >
          立即充值
        </Button>
      </div>
    </div>
  );
};

export default Index;
