import Index from '@/pages/index';
import recharge from '@/pages/recharge'
const routes = [
  {
    path: '/',
    exact: true,
    component: Index,
  },
  {
    path: '/recharge',
    exact: true,
    component: recharge,
  }

];

export default routes;
